import React, { Component, useState, useRef } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Messages, Message } from 'primereact/messages';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { Checkbox } from 'primereact/checkbox';
import {BaseConhecimentoButton} from '../../../componentes/BaseConhecimentoButton'
import usuarioService from '../../../services/usuarioService';

export default class TabExclusaoEvtsFol extends Component {
    constructor() {
        super();
        this.state = {
            mesPerApur: '',
            dropdownMes: [
                { label: "Janeiro", value: 1 },
                { label: "Fevereiro", value: 2 },
                { label: "Março", value: 3 },
                { label: "Abril", value: 4 },
                { label: "Maio", value: 5 },
                { label: "Junho", value: 6 },
                { label: "Julho", value: 7 },
                { label: "Agosto", value: 8 },
                { label: "Setembro", value: 9 },
                { label: "Outubro", value: 10 },
                { label: "Novembro", value: 11 },
                { label: "Dezembro", value: 12 },
            ],
            anoPerApur: '',
            indApuracao: '',
            indApuracaoDescricao: '',
            dropdownIndApuracao: [{ label: 'Mensal', value: 1 }, { label: 'Anual', value: 2 }],
            isAnual: '',
            isCollapsed: true,
            usuario: {},
            position: 'center',
            displayPosition: false,
            isInProgress: false,
            displayBasic: false,
            displayModal: false,
            filtros: {},
            loading: false,
            isS1200_3000: false,
            isS1202_3000: false,
            isS1207_3000: false,
            isS1210_3000: false
        };

        this.msgs = React.createRef();
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onLoadingClick = this.onLoadingClick.bind(this);
        this.addMessages = this.addMessages.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleIndApuracaoChange = this.handleIndApuracaoChange.bind(this);
    }

    onLoadingClick() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2000);
    }

    componentDidMount() {
        this.consultaUsuario();
    }

    addMessages = (messages) => {
        this.msgs.show(messages);
    }

    showMessage(titulo, mensagem, tipoMensagem, sticky, life) {
        this.msgs.show({ summary: titulo, detail: mensagem, severity: tipoMensagem, sticky: sticky, life: life });
    }

    showError(titulo, mensagens, tipoMensagem, sticky, life) {
        this.msgs.show({ summary: titulo, detail: mensagens, severity: tipoMensagem, sticky: sticky, life: life });
    }

    verificaDados() {

        const { mesPerApur, anoPerApur, isAnual, isS1200_3000, isS1202_3000, isS1207_3000, isS1210_3000} = this.state;

        const filtros = {

            mesPerApur: this.state.mesPerApur,
            anoPerApur: this.state.anoPerApur,
            isAnual: this.state.isAnual,
            isS1200_3000: this.state.isS1200_3000,
            isS1202_3000: this.state.isS1202_3000,
            isS1207_3000: this.state.isS1207_3000,
            isS1210_3000: this.state.isS1210_3000
        };

        this.setState({ filtros: filtros })

        console.log(filtros)

        if (mesPerApur && anoPerApur && (isAnual !== null && isAnual !== "")) {

            this.setState({ displayBasic: true });

        } else {
            if (isAnual === null || isAnual === "") {
                this.showError('', 'Preencha o campo de Indicativo de apuração.', 'error', false, 10000);
            }

            if (anoPerApur === "") {
                this.showError('', 'Preencha o ano de referência que deseja gerar os eventos de exclusão!', 'error', false, 10000);
            }

            if (mesPerApur === "") {
                this.showError('', 'Preencha o mês de referência que deseja gerar os eventos de exclusão!', 'error', false, 10000);
            }
            if((isS1200_3000 === false) && (isS1202_3000 === false) && (isS1207_3000 === false) && (isS1210_3000 === false)){
                this.showError('', 'Selecione ao menos um evento para gerar eventos de exclusão!', 'error', false, 10000);

            }
        }
    }

    renderFooter() {
        return (
            <div>
                <Button label="Corrigir os dados" icon="pi pi-times" onClick={() => { this.onHide() }}  className="p-button-danger" autoFocus />
                <Button label="Os dados estão corretos!" icon="pi pi-check" className="p-button-sucess" loading={this.state.loading} onClick={() => { this.excluirEventosFolha() }} />
            </div>
        );
    }

    excluirEventosFolha() {

        const msg = { severity: 'info', summary: '', detail: 'Geração de eventos iniciada, favor aguarde...', sticky: false, life: 10000 }

        this.onLoadingClick(true)
        this.addMessages(msg);
        this.setState({ isInProgress: true })
        this.setState({ displayBasic: false });

        const filtros = {

            isS1200_3000: this.state.isS1200_3000,
            isS1202_3000: this.state.isS1202_3000,
            isS1207_3000: this.state.isS1207_3000,
            isS1210_3000: this.state.isS1210_3000,
            mesPerApur: this.state.mesPerApur,
            anoPerApur: this.state.anoPerApur,
            isAnual: this.state.isAnual,
            idUsuario: parseInt(this.state.usuario.id)

        };

        const url = `/EsocialAPI/exclusao/eventos-periodicos/${filtros.isS1200_3000}/${filtros.isS1202_3000}/${filtros.isS1207_3000}/${filtros.isS1210_3000}/${filtros.anoPerApur}/${filtros.mesPerApur}/${filtros.isAnual}`;

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': 'Bearer ' + localStorage.getItem('auth-token'),
            },
            body: JSON.stringify(filtros),
        })
            .then(response => response.text())
            .then(data => {
                console.log(data);
                const mensagemDTO = JSON.parse(data);
                mensagemDTO.forEach(mensagemDTO => {
                    this.showMessage('', mensagemDTO.detail, mensagemDTO.severity, mensagemDTO.sticky, 15000);
                });
                this.onLoadingClick(false)
                this.setState({ isInProgress: false })
            })
            .catch(error => {
                console.error('Error:', error);
                this.setState({ isInProgress: false })
            });

            //this.limparFiltros();
    }

    consultaUsuario() {
        usuarioService.consultaUsuario((usuario) => {
            this.setState({ usuario });
        });
    }

    renderProgressBar(isInProgress) {
        if (isInProgress) {
            return (
                <div style={{ marginTop: '40px' }}>
                    <div>
                        <ProgressBar mode="indeterminate" />
                    </div>
                </div>
            )
        }
    }

    onClick(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }

        this.setState(state);
    }

    onHide() {
        this.setState({
            displayBasic: false
        });
    }

    limparFiltros = () => {

        console.log('Limpando os filtros....')

        this.setState({
            displayBasic: false,
            ano: '',
            mesDescricao: '',
            indApuracaoDescricao: '',
            indApuracao: '',
            mesPerApur: '',
            anoPerApur: '',
            isS1200_3000: false,
            isS1202_3000: false,
            isS1207_3000: false,
            isS1210_3000: false,
            isAnual: ''
        });
    }

    handleCheckboxChange = (event) => {

        const { name, checked } = event.target;
        this.setState(prevState => ({
            ...prevState,
            [name]: checked
        }), () => {
            // Após a atualização do estado, você pode executar qualquer ação necessária aqui
            //console.log('isS1200_3000 ' + this.state.isS1200_3000);
            //console.log('isS1202_3000 ' + this.state.isS1202_3000);
            //console.log('isS1207_3000 ' + this.state.isS1207_3000);
            //console.log('isS1210_3000 ' + this.state.isS1210_3000 + '-----');
        });
    };

    handleMesChange(mesPerApur) {

        const mesDescricaoPreview = this.state.dropdownMes.find(item => item.value === mesPerApur);

        this.setState({
            mesPerApur: mesPerApur,
            mesDescricao: mesDescricaoPreview.label
        }, () => {
            // Esta função de callback será executada após o estado ser atualizado
            //console.log('mesPerApur: ' + this.state.mesPerApur);
            //console.log('mesDescricao: ' + this.state.mesDescricao);
        });
    }

    handleAnoChange(anoPerApur) {

        this.setState({
            anoPerApur: anoPerApur
        });
    }

    handleIndApuracaoChange(indApuracao) {
        const indicativoDescricaoPreview = this.state.dropdownIndApuracao.find(item => item.value === indApuracao);

        this.setState({
            indApuracao: indApuracao,
            indApuracaoDescricao: indicativoDescricaoPreview.label
        }, () => {
            //console.log('IndApuracao:', this.state.indApuracao);
            //console.log('IndApuracaoDescricao:', this.state.indApuracaoDescricao);
        });

        // Defina isAnual com base no novo valor de indApuracao
        let isAnual;

        if (indApuracao === 2) {
            isAnual = true;
        } else {
            isAnual = false;
        }

        // Atualize o estado isAnual
        this.setState({ isAnual }, () => {
            //console.log('isAnual: ', this.state.isAnual);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        // Verifique se o estado de indApuracao mudou
        if (prevState.indApuracao !== this.state.indApuracao) {
            let isAnual;

            if (this.state.indApuracao === 2) {
                isAnual = true;
            } else {
                isAnual = false;
            }

            // Atualize o estado isAnual
            this.setState({ isAnual }, () => {
            });
        }
    }


    render() {
        const style = {

            label: {
                fontSize: 16,
                fontWeight: 'bold',
                display: 'block',
            },

            dropDown: {
                width: '80%',
                marginTop: '25px'
            },

            inputText: {
                width: '80%'
            },

            inputContainer: {
                paddingTop: '35px',
                paddingBottom: '10px'
            },

            checkboxItems: {
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                justifyContent: 'space-around',
                marginTop: '20px'
            },

            checkboxLabel: {
                marginRight: '5px'
            },

            botao: {
                textAlign: 'center',
                alignContent: 'right',
                marginTop:  '25px'
            }
        }

        const anoAtual = new Date().getFullYear();

        const ano = [];
        for (let year = anoAtual; year >= 2022; year--) {
            ano.push(year);
        }

        return (
            <div>
                <div className="content" >
                    <div className="content align-center">
                        <div>
                            <div>
                                <div>
                                    <h3>Geração em massa de eventos de exclusão (S-3000) para eventos de folha</h3>
                                    <p></p>
                                    <p style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                                    Esta funcionalidade possibilita a geração de eventos S-3000 para eventos de folha de um periodo especifico, para quando se deseja remover periodos inteiros da base do eSocial.
                                    </p>
                                    <p>
                                        Para isto, selecione os filtros abaixo, de acordo com a necessidade:
                                    </p>
                                    <p>
                                        Em caso de dúvidas na usabilidade, consulte o tutorial da nossa base de conhecimento, disponível abaixo.
                                    </p>
                                    <label style={style.label}>Eventos a excluir:</label>
                                    <div style={style.checkboxItems}>
                                        <div className="p-field-checkbox">
                                            <label style={style.checkboxLabel}>S-1200 : </label>
                                            <Checkbox
                                                name="isS1200_3000"
                                                value="isS1200_3000"
                                                onChange={this.handleCheckboxChange}
                                                checked={this.state.isS1200_3000}
                                            />
                                        </div>

                                        <div className="p-field-checkbox">
                                            <label style={style.checkboxLabel}>S-1202 :</label>
                                            <Checkbox
                                                name="isS1202_3000"
                                                value="isS1202_3000"
                                                onChange={this.handleCheckboxChange}
                                                checked={this.state.isS1202_3000}
                                            />
                                        </div>

                                        <div className="p-field-checkbox">
                                            <label style={style.checkboxLabel}>S-1207 : </label>
                                            <Checkbox
                                                name="isS1207_3000"
                                                value="isS1207_3000"
                                                onChange={this.handleCheckboxChange}
                                                checked={this.state.isS1207_3000}
                                            />
                                        </div>

                                        <div className="p-field-checkbox">
                                            <label style={style.checkboxLabel}>S-1210 :</label>
                                            <Checkbox
                                                name="isS1210_3000"
                                                value="isS1210_3000"
                                                onChange={this.handleCheckboxChange}
                                                checked={this.state.isS1210_3000}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div style={style.inputContainer} className="pure-g">

                                <div className="pure-u-1-6">
                                    <label style={style.label}>Mês: </label>
                                    <Dropdown
                                        value={this.state.mesPerApur}
                                        options={this.state.dropdownMes}
                                        onChange={(e) => this.handleMesChange(e.value)}
                                        placeholder="Escolha o mês"
                                        style={style.dropDown}
                                        required = {true}
                                    />
                                </div>

                                <div className="pure-u-1-6">
                                    <label style={style.label}>Ano:</label>
                                    <Dropdown
                                        value={this.state.anoPerApur}
                                        options={ano}
                                        onChange={(e) => this.handleAnoChange(e.value)}
                                        placeholder="Escolha o ano"
                                        style={style.dropDown}
                                        required = {true}
                                    />
                                </div>

                                <div className="pure-u-1-6">
                                    <label style={style.label}>Ind. Apuração:</label>
                                    <Dropdown
                                        value={this.state.indApuracao}
                                        options={this.state.dropdownIndApuracao}
                                        onChange={(e) => this.handleIndApuracaoChange(e.value)}
                                        placeholder="Escolha uma opção"
                                        style={style.dropDown}
                                        required = {true}
                                    />
                                </div>

                                <div className="pure-u-1-6"  style={style.botao}>
                                    <BaseConhecimentoButton url="https://base-conhecimento.osm.com.br/artigo/form-view/?id=99" label="Tutorial" variant="p-button-info" type="button" tooltip="Tutorial de Exclusão em Massa" tooltipOptions={{ position: 'top' }} icon="pi pi-question-circle" style={{ marginTop: '32px' }}/>
                                </div>

                                <div className="pure-u-1-6" style={style.botao}>
                                    <Button className="p-button-info" style={{ marginTop: '32px' }} onClick={() => this.limparFiltros()} label="Limpar Filtros" icon="pi pi-refresh" />
                                </div>

                                <div className="pure-u-1-6" style={style.botao}>
                                    <Button style={{ marginTop: '32px' }} onClick={() => this.verificaDados()} label="Gerar Evts. Exclusão" icon="pi pi-play" />
                                </div>

                            </div>

                            <Messages ref={(el) => this.msgs = el} />

                            {this.renderProgressBar(this.state.isInProgress)}

                            <div>
                                <Dialog header="Confirmação dos dados dos eventos e serem excluidos:" visible={this.state.displayBasic} style={{ width: '50vw' }} footer={this.renderFooter()} onHide={() => { this.onHide() }}>
                                    <p><b>Os dados da exclusão estão corretos?</b></p>
                                    {this.state.isS1200_3000 !== false ? <p><b>Evento(s): </b>S-1200</p> : null}
                                    {this.state.isS1202_3000 !== false ? <p><b>Evento(s): </b>S-1202</p> : null}
                                    {this.state.isS1207_3000 !== false ? <p><b>Evento(s): </b>S-1207</p> : null}
                                    {this.state.isS1210_3000 !== false ? <p><b>Evento(s): </b>S-1210</p> : null}
                                    {this.state.mesDescricao ? <p><b>Mês: </b>{this.state.mesDescricao}</p> : ""}
                                    {this.state.anoPerApur ? <p><b>Ano: </b>{this.state.anoPerApur}</p> : ""}
                                    {this.state.indApuracaoDescricao ? <p><b>Ind. Apuração: </b>{this.state.indApuracaoDescricao}</p> : ""}
                                </Dialog>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}