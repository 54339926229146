import React, { useRef } from "react";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

export const BaseConhecimentoButton = ({ url, label, variant = "p-button", type = "button", tooltip, tooltipOptions, icon, style }) => {
    const toast = useRef(null); // Criando a referência corretamente

    const handleClick = async (e) => {
        e.preventDefault();

        const apiKey = localStorage.getItem("apiKey") || "";
        const cliente = localStorage.getItem("cliente");
        const login = localStorage.getItem("login");

        try {
            if(apiKey === "") {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'Chave de API não carregada. Provavelmente sua sessão expirou, faça o login novamente.', life: 10000 });
            } else {
                const response = await fetch(`https://base-conhecimento.osm.com.br/api/gerar-token?apiKey=${apiKey}&cliente=${cliente}&login=${login}`);
                const data = await response.json();
    
                if (data.token) {
                    const fullUrl = `${url}&apiKey=${apiKey}&token=${data.token}`;
                    window.open(fullUrl, "_blank");
                } else {
                    toast.current.show({ severity: 'error', summary: 'Erro', detail: 'Erro ao gerar token. ' + data.error, life: 10000 });
                }
            }
        } catch (error) {
            console.error("Erro ao obter o token:", error);
            toast.current.show({ severity: 'error', summary: 'Erro', detail: 'Erro ao obter o token: ' + (error.message || error) + ". Verifique com a equipe de infraestrutura/rede de sua empresa/órgão se há bloqueio de rede ou firewall.", life: 10000 });
        }
    };

    return <>
        {type === "link" ? (
            <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleClick}
                style={{ textDecoration: 'underline', color: 'blue' }}
            >
                {label}
            </a>
        ) : (
            <Button className={variant} label={label} onClick={handleClick} {...(tooltip && { tooltip })} {...(tooltipOptions && { tooltipOptions })} {...(icon && { icon })} {...(style && { style })} />
        )}
        <Toast ref={toast} />
    </>
};

export default BaseConhecimentoButton;
