import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import ProgressSpinnerWithMessage from '../../../componentes/feedback/ProgressSpinnerWithMessage';

const EnvioComponent = (props) => {

    const [showProgressSpinner, setShowProgressSpinner] = useState(true);
    const [showInfoEnvio, setshowInfoEnvio] = useState(false);

    useEffect(() => {
        console.log(props.isEventosEnviados)

        if (props.isEventosEnviados) {
            console.log(props.isEventosEnviados)
            setShowProgressSpinner(false);
            setshowInfoEnvio(true);
        }
    }, [props.isEventosEnviados]);

    function direcionar(id) {
        var url = window.location.origin + "/EventoFila/" + id;
        console.log(url);
        window.location.replace(url);
    }

    function renderObjDetalhe(objDetalhe) {
        if (objDetalhe == null || objDetalhe == undefined) {
            return '-'
        } else {
            return objDetalhe.matricula + ' - ' + objDetalhe.nome;
        }
    }
    function renderInfosDetalhadas(id) {
        if(id!=null && id!=undefined){
            return (
                <div>
                    <h4>ID: {props.respostaEnvio.idEventoFila}</h4>
                    <h4>Nome Evento: {props.respostaEnvio.nomeEvento}</h4>
                    <h4>Detalhe: {renderObjDetalhe()} </h4>
                </div>
    
            )
        }
        
    }
    function renderInfoEnvio(show) {
        console.log(props);
        if (show && props.respostaEnvio!==undefined) {
            return (
                <div className="fadeInDown">
                    <Card title={props.respostaEnvio.titulo} subTitle={props.respostaEnvio.subTitulo + (props.respostaEnvio.erroMontagem ? (' - ' + props.respostaEnvio.mensagem) : "")} footer={renderFooterEnvioSucesso()}>
                        {renderInfosDetalhadas(props.respostaEnvio.idEventoFila)}
                        {/* <h4>Status: {props.respostaEnvio.status}</h4> */}
                        Para mais informações clique no botão abaixo.
                    </Card>
                </div>
            )
        }
    }

    function renderFooterEnvioSucesso() {
        return (
            <span>
                <Button label="Info" icon="pi pi-eye" style={{ margin: '10px' }} onClick={() => direcionar(props.respostaEnvio.idEventoFila)} />
            </span>
        )
    }


    return (
        <div>
            <ProgressSpinnerWithMessage show={showProgressSpinner} msg='Enviando Informações ao eSocial. Por favor, aguarde.' />
            {renderInfoEnvio(showInfoEnvio)}
        </div>
    );
}

export default EnvioComponent;