import Utils from "../utils/Util";


const mensagemService = {

    findMensagemByParams(dados, callback, errorCallback){
        fetch("/EsocialAPI/mensagem-usuario/", {
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Authorization": "Bearer " + localStorage.getItem("auth-token")
            },
            body: JSON.stringify(dados),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error("Erro na requisição.");
            }
            return response.json();
        })
        .then(resposta => {
            if (callback) callback(resposta);
        })
        .catch(error => {
            if (errorCallback) errorCallback(error);
            Utils.handleAjaxError(error);
        });
    },

    getMensagensNaoLidasUsuario(idUsuario, callback, errorCallback){
        if(idUsuario !== undefined) {
            fetch("/EsocialAPI/mensagem-usuario/nao-lidas-usuario/" +idUsuario, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + localStorage.getItem("auth-token")
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Erro na requisição.");
                }
                return response.json();
            })
            .then(resposta => {
                console.log(resposta)
                if (callback) callback(resposta);
            })
            .catch(error => {
                if (errorCallback) errorCallback(error);
                Utils.handleAjaxError(error);
            });
        }
    }
}

export default mensagemService;