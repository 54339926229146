import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import $ from 'jquery';
import { MenuBar } from '../../componentes/MenuBar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Messages } from 'primereact/messages';
import { Toast } from 'primereact/toast';
import Alert from 'react-bootstrap/Alert';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Panel } from 'primereact/panel';
import Utils from "../../utils/Util";
import TblEvtIrrfBenef from './Tabela/Totalizadores/TblEvtIrrfBenef';
import TblEvtBasesTrab from './Tabela/Totalizadores/TblEvtBasesTrab';
import TblEvtCS from './Tabela/Totalizadores/TblEvtCS';
import TblEvtIrrf from './Tabela/Totalizadores/TblEvtIrrf';
import { ProgressBar } from 'primereact/progressbar';
import { InputText } from 'primereact/inputtext';
import TblEvtBasesFGTS from './Tabela/Totalizadores/TblEvtBasesFGTS';
import usuarioService from '../../services/usuarioService';

export class RelTotalizadoresNovo extends Component {
  constructor() {
    super();
    this.state = { 
      relatoriosTot:{
        relatorioEvtIrrfBenef:{infoIrDTO:[], totApurMenDTO:[], consolidApurMenDTO:[]}, 
        relatorioEvtBasesTrab:{infoBaseCSDTOs:[], infoCpCalcDTOs:[]},
        relatorioEvtCS:{infoCPSegDTOs:[], basesRemunDTOs:[], infoCREstabDTOs:[], infoCRContribDTOs:[]},
        relatorioEvtIrrf:{infoCRMenDTOs:[]},
        relatorioEvtBasesFGTS:{basePerApurDTOs:[]}
      }
      , lista: []
      , total: 0
      , pagina: 0
      , rows: 1000
      , loading: false
      , usuario: {}
      , evento: null
      , anoPerApur: null
      , mesPerApur: null
      , indApuracao: null
      , isLoading:false
      , catalogo:{}
      , objDetalhe: ''
    };
    this.onClick = this.onClick.bind(this);
    this.onHide = this.onHide.bind(this);
    this.filtrarTotalizadores = this.filtrarTotalizadores.bind(this);
    this.handleEventoChange = this.handleEventoChange.bind(this)
    this.handleMesChange = this.handleMesChange.bind(this)
    this.handleAnoChange = this.handleAnoChange.bind(this)
    this.handleIndApurChange = this.handleIndApurChange.bind(this)
    this.consultaUsuario = this.consultaUsuario.bind(this)
    this.carregarCatalogoAtual = this.carregarCatalogoAtual.bind(this)
  }

  componentDidMount() {
    this.consultaUsuario();
    this.carregarCatalogoAtual();
  }

  handleEventoChange(evento) {
    this.setState({ evento });
  }
  handleMesChange(mesPerApur){
    this.setState({ mesPerApur });
  }
  handleAnoChange(anoPerApur){
    this.setState({ anoPerApur });
  }
  handleIndApurChange(indApuracao){
    this.setState({ indApuracao });
  }

  carregarCatalogoAtual(){
    $.ajax({
      url: "/EsocialAPI/catalogo/vigente-lite",
      dataType: 'json',
      error: function (e) {
          console.log(e);
      },
      success: function (resposta) {
          this.setState({ catalogo: resposta });
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }

  consultaUsuario() {
    usuarioService.consultaUsuario((usuario) => {
      this.setState({ usuario });
    });
  }

  filtrarTotalizadores() {
    this.setState({isLoading: true});
    var filtro = {
      evento: this.state.evento
      , anoPerApur: this.state.anoPerApur
      , mesPerApur: this.state.mesPerApur
      , indApuracao: this.state.indApuracao
      , objDetalhe: this.state.objDetalhe
    };

    let arrMensagem = [];

    if(this.state.evento === null || this.state.evento === '') {
      arrMensagem.push({severity:'info', detail:'Evento é campo obrigatório.'});
    }

    if((this.state.anoPerApur === null || this.state.anoPerApur === '')) {
      arrMensagem.push({severity:'info', detail:'Ano apuração é campo obrigatório.'});
    }

    if((this.state.mesPerApur === null || this.state.mesPerApur === '')) {
      arrMensagem.push({severity:'info', detail:'Mês apuração é campo obrigatório.'});
    }

    if((this.state.indApuracao === null || this.state.indApuracao === '')) {
      arrMensagem.push({severity:'info', detail:'Indicativo de apuração é campo obrigatório.'});
    }
    
    // Se exibir mensagem, não executa
    if(arrMensagem.length > 0) {
      this.showToast(arrMensagem);
    } else {
      this.setState({ loading: true });

      $.ajax({
        type: "POST",
        data: JSON.stringify(filtro),
        url: "/EsocialAPI/relatorios/totalizadores/novo",
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        success: function (resposta) {
          console.log(resposta);
          this.setState({isLoading: false, relatoriosTot: resposta})
          //this.setState({ loading: false, lista: resposta.listaCpf, total: resposta.totalPaginas, pagina: resposta.pagina });
        }.bind(this),
        beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); },
        error: Utils.handleAjaxError
      });
    }
  }

  showMessage(titulo, mensagem, tipoMensagem) {
    this.messages.show({ severity: tipoMensagem, summary: titulo, detail: mensagem });
  }

  showToast(arrMensagens) {
    this.toast.show(arrMensagens);
  }

  onClick() {
    this.setState({visible: true});
  }

  onHide() {
      this.setState({visible: false});
  }

  renderTabelaRelTotalizador(evento, relatorios){

    if(evento == 'S5001'){
      return <TblEvtBasesTrab relatorios={relatorios.relatorioEvtBasesTrab} anoPerApur={this.state.anoPerApur} mesPerApur={this.state.mesPerApur} indApuracao={this.state.indApuracao ? 'ANUAL' : 'MENSAL'}/>
    }
    if(evento == 'S5002'){
      return <TblEvtIrrfBenef relatorios={relatorios.relatorioEvtIrrfBenef} anoPerApur={this.state.anoPerApur} mesPerApur={this.state.mesPerApur} indApuracao={this.state.indApuracao ? 'ANUAL' : 'MENSAL'}/>
    }
    if(evento == 'S5003'){
      return <TblEvtBasesFGTS relatorios={relatorios.relatorioEvtBasesFGTS} anoPerApur={this.state.anoPerApur} mesPerApur={this.state.mesPerApur} indApuracao={this.state.indApuracao ? 'ANUAL' : 'MENSAL'}/>
    }
    if(evento == 'S5011'){
      return <TblEvtCS relatorios={relatorios.relatorioEvtCS} anoPerApur={this.state.anoPerApur} mesPerApur={this.state.mesPerApur} indApuracao={this.state.indApuracao ? 'ANUAL' : 'MENSAL'}></TblEvtCS>
    }
    if(evento == 'S5012'){
      return <TblEvtIrrf relatorios={relatorios.relatorioEvtIrrf} anoPerApur={this.state.anoPerApur} mesPerApur={this.state.mesPerApur} indApuracao={this.state.indApuracao ? 'ANUAL' : 'MENSAL'}></TblEvtIrrf>
    }
  }

  handleObjDetalheChange(objDetalhe) {
    this.setState({ objDetalhe: objDetalhe.toUpperCase() });
  }

  render() {
    const style = {
      label: {
          fontSize: 16,
          fontWeight: 'bold',
          display: 'block',
      },
      dropDown: {
          width: '80%'
      },
      inputText: {
          width: '80%'
      },
      inputContainer: {
          paddingTop: '10px',
          paddingBottom: '10px'
      },
      toolbar: {
          margin: 0,
          padding: 0,
      },
    }

    const month = [
      { label: "Janeiro", value: 1 },
      { label: "Fevereiro", value: 2 },
      { label: "Março", value: 3 },
      { label: "Abril", value: 4 },
      { label: "Maio", value: 5 },
      { label: "Junho", value: 6 },
      { label: "Julho", value: 7 },
      { label: "Agosto", value: 8 },
      { label: "Setembro", value: 9 },
      { label: "Outubro", value: 10 },
      { label: "Novembro", value: 11 },
      { label: "Dezembro", value: 12 },
    ];

    const currentYear = new Date().getFullYear();
    const anos = [];
    anos.push({ label: "Todos", value: "" });
    for (let year = currentYear; year >= 2018; year--) {
        anos.push(year);
    }

    const indApuracao = [{label: 'Mensal', value:false}, {label: 'Anual', value:true}];

    const dropdownEventos = [
      {label:'S5001 - Informações das Contribuições Sociais por Trabalhador',value:'S5001'},
      {label:'S5002 - Imposto de Renda Retido na Fonte por Trabalhador',value:'S5002'},
      {label:'S5003 - Informações do FGTS por Trabalhador',value:'S5003'},
      {label:'S5011 - Informações das Contribuições Sociais Consolidadas por Contribuinte',value:'S5011'}, 
      {label:'S5012 - Evento IRRF Consolidado por Contribuinte',value:'S5012'}
    ];

    const items = [
      { label: 'Home', icon: 'pi pi-home', url: '/home' },
      { label: 'Relatórios', icon: 'pi pi-home', url: '/relatorio/inconsistencias' },
      { label: 'Relatório de Totalizadores', icon: 'pi-home', url: '/relatorio/totalizadores' }
    ];

    return (
      <div>
        <MenuBar usuario={this.state.usuario} />
        <div className="content">
          <div className="pure-u-5-5" style={{ marginTop: '15px' }}>
            <Panel header="Totalizadores">
              <Toast ref={(el) => this.toast = el} position="bottomright"></Toast>
              <BreadCrumb home="principal" model={items} />
              <div className="pure-u-1 content-section implementation" style={{ marginTop: '15px' }}>
                <Messages ref={(el) => (this.messages = el)}></Messages>
                <Panel header="Filtros">
                  <div style={{ margin: '15px' }} >
                    <div style={style.inputContainer} className="pure-g">
                      <div className="pure-u-1-1">
                        <Alert variant="primary">
                          <p>Para compreensão do significado das colunas do relatório, deve consultar o leiaute do eSocial.</p>
                          <p style={{ margin: '10px 0' }}>
                            <a href={this.state.catalogo.linkLayout} target='_blank'>Leiaute eSocial {this.state.catalogo.versao}</a>{' | '}
                            <a href={this.state.catalogo.linkLayout + '#evtBasesTrab'} target='_blank'>5001</a>{' | '}
                            <a href={this.state.catalogo.linkLayout + '#evtIrrfBenef'} target='_blank'>5002</a>{' | '}
                            <a href={this.state.catalogo.linkLayout + '#evtBasesFGTS'} target='_blank'>5003</a>{' | '}
                            <a href={this.state.catalogo.linkLayout + '#evtCS'} target='_blank'>5011</a>{' | '}
                            <a href={this.state.catalogo.linkLayout + '#evtIrrf'} target='_blank'>5012</a>{' | '}
                            <a href={this.state.catalogo.linkLayout + '#evtFGTS'} target='_blank'>5013</a>
                          </p>
                        </Alert>
                      </div>
                    </div>
                    <div style={style.inputContainer} className="pure-g">
                      <div className="pure-u-1-5">
                          <label style={style.label}>Evento: </label>
                          <Dropdown
                              value={this.state.evento}
                              options={dropdownEventos}
                              onChange={(e) => { this.handleEventoChange(e.value) }}
                              placeholder="Escolha o evento"
                              style={style.dropDown}
                          />
                      </div>
                      <div className="pure-u-1-5">
                          <label style={style.label}>Mês Apuração: </label>
                          <Dropdown
                              value={this.state.mesPerApur}
                              options={month}
                              onChange={(e) => { this.handleMesChange(e.value) }}
                              placeholder="Escolha o Mês"
                              style={style.dropDown}
                          />
                      </div>
                      <div className="pure-u-1-5">
                          <label style={style.label}>Ano Apuração: </label>
                          <Dropdown
                              value={this.state.anoPerApur}
                              options={anos}
                              onChange={(e) => { this.handleAnoChange(e.value) }}
                              placeholder="Escolha o Ano"
                              style={style.dropDown}
                          />
                      </div>
                      <div className="pure-u-1-5">
                          <label style={style.label}>Ind. Apuração: </label>
                          <Dropdown
                              value={this.state.indApuracao}
                              options={indApuracao}
                              onChange={(e) => { this.handleIndApurChange(e.value) }}
                              placeholder="Escolha a Apuração"
                              style={style.dropDown}
                          />
                      </div>
                      <div className="pure-u-1-5">
                          <label style={style.label}>Detalhes: </label>
                            <InputText
                                id="objDetalhe"
                                value={this.state.objDetalhe}
                                onChange={(e) => this.handleObjDetalheChange(e.target.value)}
                                style={style.inputText}
                            />
                      </div>
                    </div>
                  </div>
                  <div className="pure-u-3-3" style={{ textAlign: 'right' }}>
                    <Button icon="pi pi-refresh" style={{ margin: '4px' }} onClick={() => this.filtrarTotalizadores()} tooltip="Filtrar" tooltipOptions={{ position: 'right' }} label="Filtrar" />
                  </div>
                </Panel>
                {this.state.isLoading ? <ProgressBar mode="indeterminate" /> : ''}
                {this.renderTabelaRelTotalizador(this.state.evento, this.state.relatoriosTot)}
              </div>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}
