import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import $ from 'jquery';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import XMLViewer from 'react-xml-viewer';
import { Badge } from 'primereact/badge';
import {BaseConhecimentoButton} from '../../../componentes/BaseConhecimentoButton';

export class HistoricoEvento extends Component {
  constructor() {
    super();

    this.state = {
      lista: [],
      eventos: [],
      usuario: {},
      xmljson: "",
      visiblePreview: false,
      sendPreview: false,
      xml: {},
      idEventoFila: 0,
      historico: [],
      ocorrencias: {},
      visibleOcorrencia: false,

    }
    this.handleClickXmlEnviado = this.handleClickXmlEnviado.bind(this)
    this.handleClickXmlRecebido = this.handleClickXmlRecebido.bind(this)
    this.handleClickXmlOcorrencia = this.handleClickXmlOcorrencia.bind(this)
    this.actionBar = this.actionBar.bind(this)
  }

  componentWillReceiveProps(props) {
    //this.handleChange(props.changed)
    this.setState({ idEventoFila: props.idEventoFila });
  }
  componentDidMount() {
    this.atualizaDialog(this.props.idEventoFila);
  }
  handleChange(changed){
    if(!changed){
      this.atualizaDialog(this.props.idEventoFila);
    }

  }
  dateTemplateSaida(rowData) {
    var hora = new Date(rowData['dateEventoSaida']).toLocaleTimeString('pt-BR');
    var data = new Date(rowData['dateEventoSaida']).toLocaleDateString();
    return data + ' ' + hora
  }
  dateTemplateEntrada(rowData) {
    if (rowData['dateEventoEntrada'] == null) {
      return "Sem Retorno";
    } else {
      var hora = new Date(rowData['dateEventoEntrada']).toLocaleTimeString('pt-BR');
      var data = new Date(rowData['dateEventoEntrada']).toLocaleDateString();
      return data + ' ' + hora
    }
  }
  actionTemplate(rowData) {
    if (rowData.statusEvento === 'PENDENTE') {
      return <i className="pi pi-sign-in"></i>;
    }
    if (rowData.statusEvento === 'ENVIADO') {
      return <i className="pi pi-sign-in"></i>;
    }
    if (rowData.statusEvento === 'ENVIANDO') {
      return <i className="pi pi-envelope"></i>;
    }
    if (rowData.statusEvento === 'REJEITADO') {
      return <i className="pi pi-times-circle"></i>;
    }
    if (rowData.statusEvento === 'PROCESSADO') {
      return <i className="pi pi-check-circle"></i>;
    }
    if (rowData.statusEvento === 'RECEBIDO') {
      return <i className="pi pi-check-circle"></i>;
    }
  }

  columnIdentificador(rowData) {
    return <Badge value={rowData.identificador} severity="info"></Badge>
  }

  columnRecibo(rowData) {
    return (
      rowData.recibo !== null ?
      <Badge value={rowData.recibo} severity="success"></Badge>
      : '-'
    )
  }

  handleClickXmlEnviado(rowData){
    this.setState({xml: rowData.xmlSaida})
    this.setState({visiblePreview:true})

  }

  handleClickXmlRecebido(rowData){
    this.setState({xml: rowData.xmlEntrada})
    this.setState({visiblePreview:true})
  }

  handleClickXmlOcorrencia(rowData){
    this.setState({visibleOcorrencia:true})
    this.setState({ocorrencias: rowData.ocorrencias})
  }

  actionBar(rowData) {

    return (
      <div style={{ display: 'inline-block' }}>
        <Button style={{margin:'5px'}} icon="pi pi-file-o" className="p-button-sm p-button-rounded p-button-success" onClick={()=>this.handleClickXmlEnviado(rowData)} tooltip="XML Enviado"/>
        <Button style={{margin:'5px'}} icon="pi pi-file-o" className="p-button-sm p-button-rounded p-button-warning" onClick={()=>this.handleClickXmlRecebido(rowData)} tooltip="XML retornado pelo eSocial"/>
        {rowData.ocorrencias !== null ?
          <Button style={{margin:'5px'}} icon="pi pi-exclamation-triangle" className="p-button-sm p-button-rounded p-button-danger" onClick={()=>this.handleClickXmlOcorrencia(rowData)} tooltip="Ocorrencias"/>
          : null
        }

        {/* <OcorrenciaHist idHistEventoMensageria={rowData.idHistEventoMensageria} /> */}
      </div>
    )
  }
  atualizaDialog() {
    $.ajax({
      url: "/EsocialAPI/api/processamento/visualizar/historico/" + this.props.idEventoFila,
      type: 'GET',
      error: function (error) {
        console.log(error);
        //alert(error);
      },
      success: function (retorno) {
        console.log(retorno);
        this.setState({ historico: retorno });
      }.bind(this),
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }

  renderXmlViewer(xml){
    if(xml!=null && xml!=undefined){
      return <XMLViewer xml={xml} />
    }
    return <p>Erro ao processar o XML, verifique se o evento foi enviado ou o lote foi consultado em Processamento Lote.</p>
  }
  render() {
    return (
      <div style={{ height: "400px", textAlign: "left" }}>
        <Dialog header="Visualização evento XML" visible={this.state.visiblePreview} style={{ width: '50vw' }} modal onHide={()=>this.setState({visiblePreview: false})}>
           {this.renderXmlViewer(this.state.xml)}
        </Dialog>

        <Dialog header="Ocorrências" visible={this.state.visibleOcorrencia} style={{ width: '50vw' }} modal onHide={()=>this.setState({visibleOcorrencia: false})}>
          {this.state.ocorrencias.length > 0 ? (
            <ul className="ocorrencias-list" style={{listStyleType: 'none', padding: 0 }}>
              {this.state.ocorrencias.map((ocorrencia, index) => (
                <li key={index} className="ocorrencia-item"  style={{ border: '1px solid #ccc', margin:'10px', padding: '10px' }}>
                  <div>
                    <strong>Tipo:</strong> {ocorrencia.tipo}<br />
                    <strong>Código:</strong> {ocorrencia.codigo}<br />
                    <strong>Descrição:</strong> {ocorrencia.descricao}<br />
                    <strong>Localização:</strong> {ocorrencia.localizacao}<br />
                    {ocorrencia.url !== null && (
                      <div>
                        <strong>Base de Conhecimento:</strong>{" "}
                        <BaseConhecimentoButton url={ocorrencia.url} label="Tente este conhecimento" />
                        <br />
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div>Nenhuma ocorrência registrada</div>
          )}
        </Dialog>

        <DataTable 
          value={this.state.historico} 
          emptyMessage="O evento não possui histórico" 
          scrollable={true} 
          scrollHeight="300px" 
          autoLayout={true}
        >
          <Column field="idHistEventoMensageria" header="ID" style={{ width: '3em' }} />
          {/*<Column body={this.actionTemplate} field="statusEvento" header="Status" style={{ width: '8%', textAlign: 'center' }} />*/}
          <Column body={this.dateTemplateSaida} field="dateEventoSaida" header="Data envio" style={{ width: '7em'}} />
          <Column body={this.dateTemplateEntrada} field="dateEventoEntrada" header="Data retorno"  style={{ width: '7em'}} />
          <Column body={this.columnIdentificador} field="identificador" header="Identificador evento" style={{ width: '17em' }} />
          <Column body={this.columnRecibo} field="recibo" header="Recibo" style={{ width: '9em' }} />
          <Column header="Ação" body={this.actionBar} style={{ textAlign: 'center' }} />
        </DataTable>
        {/*
        <div style={{ display: 'inline-block' }}>
          <div style={{ fontSize: '9', display: 'inline-block', paddingRight: '20px' }}><i className="pi pi-sign-in"></i> Enviado</div>
          <div style={{ fontSize: '9', display: 'inline-block', paddingRight: '20px' }}><i className="pi pi-envelope"></i> Enviando</div>
          <div style={{ fontSize: '9', display: 'inline-block', paddingRight: '20px' }}><i className="pi pi-times-circle"></i> Rejeitado</div>
          <div style={{ fontSize: '9', display: 'inline-block' }}><i className="pi pi-check-circle"></i> Processado</div>
        </div>
        */}
      </div>
    );
  }
}